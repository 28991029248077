
import React from "react"
import "./src/styles/index.scss";
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
    return (
        <>
            {element}
            <Script src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0" />
            <Script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" />
        </>
    )
}

